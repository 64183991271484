import { createRouter, createWebHistory } from 'vue-router'
import {useTitle} from '@vueuse/core'
import {authGuard} from "@/router/guards/authGuard";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {title: 'Gent AR - Home'},
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/experience',
      name: 'experience',
      meta: {title: 'Gent AR - Experience'},
      component: () => import('../views/ExperienceView.vue')
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: {title: 'Unauthorized'},
      component: () => import('../views/UnauthorizedView.vue'),
    },
    {
      path: '/admin',
      name: 'admin',
      meta: {title: 'Login'},
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/admin/dashboard',
      name: 'dashboard',
      component: () => import('../views/DashboardView.vue'),
      meta: { requiresAuth: true },
      beforeEnter: authGuard,
      children: [
        {
          path: '',
          name: 'MainDashboard',
          meta: {title: 'Dashboard'},
          component: () => import('../components/organisms/MainDashboard.vue')
        },
        {
          path: 'map',
          name: 'MapDashboard',
          meta: {title: 'Dashboard - Map'},
          component: () => import('../components/organisms/MapDashboard.vue')
        },
        {
          path: 'add',
          name: 'AddDashboard',
          meta: {title: 'Dashboard - Add Panel'},
          component: () => import('../components/organisms/AddDashboard.vue')
        }
      ]
    },
    {
      path: '/admin/dashboard/edit/:markerId',
      name: 'editPosition',
      component: () => import('../components/ARScene.vue'),
      meta: { requiresAuth: true },
      beforeEnter: authGuard
    },
    {
      path: '/accessibility',
      name: 'accessibility',
      meta: {title: 'Accessibility Declaration'},
      component: () => import('../views/AccessibilityView.vue')
    },
    {
      path: '/test',
      name: 'load model test',
      meta: {title: 'Test'},
      component: () => import('../views/LoadTestView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      meta: {title: 'Not Found', requiresAuth: false},
      component: () => import('../views/NotFoundView.vue')
    },

  ]
})

export default router

router.beforeEach((to) => {
  const title = to.meta.title || 'Project FSD'
  useTitle(title as string)
})
