import type {RouteLocationNormalized} from "vue-router";
import {useUserStore} from "@/stores/userStore";

export function authGuard(to: RouteLocationNormalized) {
    const userStore = useUserStore();

    if (to.meta.requiresAuth && !userStore.isAuthenticated) {
        console.log(`Redirecting from ${to.fullPath} to unauthorized`);
        return {
            name: 'unauthorized',
        };
    }
}
