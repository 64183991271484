import { myAxios } from '@/instances/myAxios'

// LOGIN
const postLogin = <T>(username: string, password: string) =>
    myAxios.post<T>(`/login/`, { username, password })

const postLogout = <T>(token: string) =>
  myAxios.post<T>(`/logout/`, {},{
    headers: {
      Authorization: `Token ${token}`,
    },
  });


export { postLogin, postLogout }
